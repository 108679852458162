<template lang="pug">
.content
  section.container-fluid.p-t-20
    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item
        router-link(to="/page") 页面管理
      a-breadcrumb-item {{ data.id ? '编辑页面' : '新增页面' }}
      
    .m-t-15.m-b-20
      h1.section-title.inline {{ data.id ? '编辑页面' : '新增页面' }}
      a-button.m-l-15(size="small" @click="() => { this.$router.push({ name: 'Page Management' }) }") 页面管理

  .container-fluid.clearfix(style="padding-right: 15px; margin-right: 335px;")
    a-form-model.page-formz(ref="form" :model="data")
    
      .inner-content-right
        .editor-wrap
          a-card.m-b-15(size="small" :bordered="false" :bodyStyle="{ padding: 0 }")
            a-input(size="large" v-model="data.title" placeholder="页面标题")
            
          a-card.m-b-15(size="small" :bodyStyle="{ padding: 0 }")
            vc-tinymce(v-model="data.content")

        hr(style="margin: 30px 0; border-top: dashed 1px #ccc; border-bottom: none; height: 0;")

        .editor-wrap
          a-card.m-b-15(size="small" :bordered="false" :bodyStyle="{ padding: 0 }")
            a-input(size="large" v-model="data.title_en" placeholder="页面标题（英文）")
            
          a-card.m-b-15(size="small" :bodyStyle="{ padding: 0 }")
            vc-tinymce(v-model="data.content_en")
        
        hr(style="margin: 30px 0; border-top: dashed 1px #ccc; border-bottom: none; height: 0;")

        a-card.m-b-15(size="small" title="描述:")
          a-textarea(v-model="data.excerpt")

        //- pre {{ data }}

      .secondary-sidebar-right
        a-card.m-b-15(size="small" title="发布")
          a-form-model-item.m-b-0(label="状态:"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol")
            a-select.full-width(v-model="data.status")
              //- a-select-option(value="pending") 待审核
              a-select-option(value="publish") 正常
              //- a-select-option(value="private") 私密
              a-select-option(value="draft") 草稿
              //- a-select-option(value="trash") 回收站
          
          //- a-form-model-item.m-b-5(label="作者:"
          //-   :label-col="formItemLayout.labelCol"
          //-   :wrapper-col="formItemLayout.wrapperCol")
          //-   a-select.full-width(v-model="data.creator_id")
          //-     a-select-option(:value="item.id" v-for="(item, index) in users" :key="index") {{ item.display_name }}

          a-form-model-item.m-b-0(label="排 序:"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol")
            a-input-number(:min="0" v-model="data.menu_order")

          //- a-form-model-item.m-b-0(label="查阅数:"
          //-   :label-col="formItemLayout.labelCol"
          //-   :wrapper-col="formItemLayout.wrapperCol")
          //-   a-input-number(v-model="data.view_count" :min="0")
          //- a-form-model-item.m-b-0(label="点赞数:"
          //-   :label-col="formItemLayout.labelCol"
          //-   :wrapper-col="formItemLayout.wrapperCol")
          //-   a-input-number(v-model="data.like_count" :min="0")
          //- a-form-model-item.m-b-0(label="评论数:"
            :label-col="formItemLayout.labelCol"
            :wrapper-col="formItemLayout.wrapperCol")
            a-input-number(v-model="data.comment_count" :min="0")
          
          a-button.submit(type="primary" block @click="submit") 提交
        
        a-card.m-b-15(size="small" title="横幅")
          small(slot="extra") {{ upload_options.thumbnail.tip }}
          vc-uploader(:data="data.thumbnail" :options="upload_options.thumbnail" @success="onSuccess")
          
        a-card.m-b-15(size="small" title="相册" :bodyStyle="{ paddingRight: 0 }")
          small(slot="extra") {{ upload_options.gallery.tip }}
          vc-uploader(:data="data.gallery" :options="upload_options.gallery" @success="onSuccess")

        //- a-card.m-b-15(size="small" title="视频")
        //-   small(slot="extra") {{ upload_options.video.tip }}
        //-   vc-uploader(:data="data.video" :options="upload_options.video" @success="onSuccess")

        //- pre(style="font-size:10px;") {{ post_tag }}
        //- pre(style="font-size:10px;") {{ tag_ids }}
</template>

<script>
//- import { mapState } from 'vuex'

import { Empty } from 'ant-design-vue';

import vcUploader from '@/components/vcUploader'
import vcTinyMCE from '@/components/vcTinyMCE'

export default {
  name: 'PageForm',
  components: {
    'vc-uploader': vcUploader,
    'vc-tinymce': vcTinyMCE
  },
  props: {
    data: {
      type: Object,
      default: null
    },
    rules: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tag_dis: false,
      tag_loading: false,
      
      activeKey: [],   // collapse

      formItemLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      },

      users: [],
      
      replaceFields: {
        children:'children',
        title: 'name',
        key: 'id',
        value: 'id'
      },
      
      upload_options: {
        thumbnail: {
          accept: ['.jpg', '.gif', 'image/webp'],
          multiple: false,
          app: 'admin',
          module: 'post',
          key: 'thumbnail',
          tip: '尺寸大小建议 1920 × 402 像素'
        },
        gallery: {
          multiple: true,
          maxfile: -1,
          app: 'admin',
          module: 'post',
          key: 'gallery',
          tip: '尺寸大小建议 640 × 641 像素'
        },
        video: {
          multiple: true,
          app: 'admin',
          module: 'post',
          key: 'video',
          tip: '尺寸大小建议 640 × 641 像素'
        },
      },
    }
  },
  computed: {
  },
  methods: {    
    //- 创建标签
    async createTag() {
    },
    //- 获取父级
    async getParent() {},
    //- 获取分类标签
    async getTaxonomies(taxonomy) {
      this[taxonomy] = await this.$http.get(taxonomy, {
        params: {
          limit: -1
        }
      });
    },
    //- 获取用户
    async getUsers() {
      this.users = await this.$http.get('user', {
        params: {
          limit: -1
        }
      });
    },

    async submit() {
      this.$refs.form.validate(async valid => {
        if (!valid) return this.$message.error('提交失败');
        try {
          const method = this.data.id ? 'put' : 'post';
          const url = this.data.id ? `page/${this.data.id}`: 'page';
          await this.$http[method](url, this.data);
          this.$refs.form.resetFields();
          this.$message.success('提交成功');
        } catch(err) {
          this.loading = false;
          this.$message.error(err.message);
        }
      })
    },

    /*********************************************************
     * @description 上传组件回调 删除/上传成功
     *********************************************************/
    // 删除/上传成功
    onSuccess(key, res) {
      this.data[key] = res;
    },
  },
  created() {
    //- this.getParent();
    this.getUsers();
  },
  mounted() {
  },
  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
}
</script>

<style lang="less" scoped>
.secondary-sidebar-right {
  float: left;
  width: 310px;
  margin-right: -310px;
}
.inner-content-right {
  float: left;
  width: 100%;
  margin-right: 15px;
}

.page-form {
  .ant-divider { margin: 16px 0;}
  .ant-form-item {
    margin: 12px 0;
  }
  .ant-card {
    .ant-form-item {
      margin: 6px 0;
      label { font-size: 12px;}
    }
    .ant-form-item-label { line-height: 35px;}
    .ant-form-item-control { line-height: 36px;}
    .ant-input,
    .ant-input-number {
      height: 30px;
    }
  }
}
</style>